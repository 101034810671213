<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="d-none d-md-block">
      <hero-component
        btn-text="Get started on your travel policy"
        background-url="/img/Travel-US-Background-Desktop.jpg"
        background-position="top"
        btn-link="/travelinfocanada"
      >
        We've got your<br>
        jet-setting<br>
        covered.
      </hero-component>
      <div class="section2 pb-16">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your travel adventures with Blanket
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                Get peace of mind for your best laid plans.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-center mt-16 mb-n6">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-calendar-check
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-wallet-travel
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-hand-extended
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="10"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Plan your next trip with confidence
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                <!-- 10-Day Free Look <br> Period -->
                Create a travel insurance package that’s best for you
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Get dedicated support along the way
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex flex-column justify-center s2-desc">
              We’ll protect against unforeseen events that could cancel or interrupt your vacation resulting in the loss of any pre-paid, non-refundable trip costs.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              With customizable travel insurance products, we’ll ensure the best coverage for your unique travel experiences in case the unexpected disruption happens.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Whether you’re just getting started on planning a trip or have a policy and need help during your trip, our in-house claims and service team can help.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/travelinfocanada"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your (mis)adventures, covered.
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Blanket’s travel insurance can cover your unique travel needs with the following products:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in travelNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  For trips with other unique activities and needs, optional coverages are available as well.
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in optionalCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get Travel coverage in less time than it takes to clean your sunglasses.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/travelinfocanada"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your travel policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          src="/img/renters-2022/Mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!---------------------------------COVID-19 Update - October 4, 2022------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleCovid19Faq"
          >
            <v-col
              cols="12"
              class="faq-header-desk"
            >
              COVID-19 Update - October 4, 2022
              <v-icon
                v-if="!covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="covid19Faq">
          <v-col>
            <v-card>
              <v-card-text>
                <p>
                  <br>
                  COVID-19 benefits are applicable if, before your departure date, you’re compliant with the most current Canadian Federal Government COVID-19 vaccine requirements for entry and/or return to Canada.
                  <br>
                  <br>
                  Since there aren’t currently Federal Government COVID-19 vaccine requirements for entry and/or return to Canada, TuGo’s COVID-19 coverage is available regardless of vaccination status, for policies purchased as of October 4, 2022.
                  <br>
                  <br>
                  See details below.
                  <br>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-desk"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-if="faq.subSection"
                class="mt-5"
              >
                <v-expansion-panels
                  focusable
                  accordion
                >
                  <v-expansion-panel
                    v-for="item in faq.subSection"
                    :key="item.id"
                  >
                    <v-expansion-panel-header class=" text-h4 font-weight-bold">
                      <div>
                        <v-icon
                          color="#00A1B7"
                          class="subSection mr-5"
                        >
                          {{ item.icon }}
                        </v-icon>
                        {{ item.title }}
                      </div>
                      <template v-slot:actions>
                        <v-icon
                          color="#005F6C"
                        >
                          mdi-chevron-down
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <br>
                      </div>
                      <v-expansion-panels
                        focusable
                        accordion
                      >
                        <v-expansion-panel
                          v-for="subItem in item.list"
                          :key="subItem.id"
                        >
                          <v-expansion-panel-header class="subSection-ques text-h4 font-weight-bold">
                            <div>
                              {{ subItem.ques }}
                            </div>
                            <template v-slot:actions>
                              <v-icon
                                color="#005F6C"
                              >
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div>
                              <br>
                            </div>
                            <div v-html="subItem.ans"></div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <div v-show="tabletDevice">
      <div class="section1-tablet">
        <div class="sora-tablet white--text">
          <!-- We’ve got your <br>
          adventurous<br>
          side, covered. -->
          We've got your<br>
          jet-setting<br>
          covered.
        </div>
        <div class="cta-btn-tablet">
          <v-btn
            class="mint darkgrey--text font-weight-bold text-h4"
            large
            to="/travelinfocanada"
          >
            Get started on your travel policy
          </v-btn>
        </div>
      </div>
      <div class="section2-tablet pb-16 px-2">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your travel adventures with Blanket
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                Get peace of mind for your best laid plans.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex align-start justify-center mt-16 mb-n6">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-calendar-check
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-wallet-travel
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-hand-extended
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="12"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Plan your next trip with confidence
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                <!-- 10-Day Free Look <br> Period -->
                Create a travel insurance package that’s best for you
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Get dedicated support along the way
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center s2-desc">
              We’ll protect against unforeseen events that could cancel or interrupt your vacation resulting in the loss of any pre-paid, non-refundable trip costs.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              With customizable travel insurance products, we’ll ensure the best coverage for your unique travel experiences in case the unexpected disruption happens.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Whether you’re just getting started on planning a trip or have a policy and need help during your trip, our in-house claims and service team can help.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/travelinfocanada"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your (mis)adventures, covered.
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Blanket’s travel insurance can cover your unique travel needs with the following products:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in travelNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  For trips with other unique activities and needs, optional coverages are available as well.
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in optionalCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get Travel coverage in less time than it takes to clean your sunglasses.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/travelinfocanada"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your travel policy today</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!---------------------------------COVID-19 Update - October 4, 2022------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleCovid19Faq"
          >
            <v-col
              cols="12"
              class="faq-header-tablet"
            >
              COVID-19 Update - October 4, 2022
              <v-icon
                v-if="!covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="covid19Faq">
          <v-col>
            <v-card>
              <v-card-text>
                <p>
                  <br>
                  COVID-19 benefits are applicable if, before your departure date, you’re compliant with the most current Canadian Federal Government COVID-19 vaccine requirements for entry and/or return to Canada.
                  <br>
                  <br>
                  Since there aren’t currently Federal Government COVID-19 vaccine requirements for entry and/or return to Canada, TuGo’s COVID-19 coverage is available regardless of vaccination status, for policies purchased as of October 4, 2022.
                  <br>
                  <br>
                  See details below.
                  <br>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-tablet"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-if="faq.subSection"
                class="mt-5"
              >
                <v-expansion-panels
                  focusable
                  accordion
                >
                  <v-expansion-panel
                    v-for="item in faq.subSection"
                    :key="item.id"
                  >
                    <v-expansion-panel-header class=" text-h4 font-weight-bold">
                      <div>
                        <v-icon
                          color="#00A1B7"
                          class="subSection mr-5"
                        >
                          {{ item.icon }}
                        </v-icon>
                        {{ item.title }}
                      </div>
                      <template v-slot:actions>
                        <v-icon
                          color="#005F6C"
                        >
                          mdi-chevron-down
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <br>
                      </div>
                      <v-expansion-panels
                        focusable
                        accordion
                      >
                        <v-expansion-panel
                          v-for="subItem in item.list"
                          :key="subItem.id"
                        >
                          <v-expansion-panel-header class="subSection-ques text-h4 font-weight-bold">
                            <div>
                              {{ subItem.ques }}
                            </div>
                            <template v-slot:actions>
                              <v-icon
                                color="#005F6C"
                              >
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div>
                              <br>
                            </div>
                            <div v-html="subItem.ans"></div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-if="mobileDevice">
      <div
        class="section1-mobile"
      ></div>
      <div class="section2-mobile">
        <div class="section2-title-mobile">
          <v-row class="mb-5">
            <v-col class="pt-5">
              <!-- We’ve got your <br>
              adventurous<br>
              side, covered. -->
              We've got your<br>
              jet-setting<br>
              covered.
            </v-col>
          </v-row>
          <v-btn
            x-large
            class="mint mx-auto py-8"
            to="/travelinfocanada"
          >
            <span class="section2-btn-txt-mobile pt-1">get started on <br> your travel policy</span>
          </v-btn>
        </div>
      </div>
      <div class="section3-mobile">
        <v-row class="pt-10">
          <v-col class="section3-title-mobile">
            Protect your travel adventures with Blanket
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="section3-sub-mobile">
            Get peace of mind for your best laid plans.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-calendar-check
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Plan your next trip with confidence
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            We’ll protect against unforeseen events that could cancel or interrupt your vacation resulting in the loss of any pre-paid, non-refundable trip costs.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              large
              class="primary--text"
            >
              mdi-wallet-travel
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            <!-- 10-Day Free Look Period -->
            Create a travel insurance package that’s best for you
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            With customizable travel insurance products, we’ll ensure the best coverage for your unique travel experiences in case the unexpected disruption happens.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-hand-extended
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Get dedicated support along the way
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Whether you’re just getting started on planning a trip or have a policy and need help during your trip, our in-house claims and service team can help.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="d-flex justify-center">
            <v-btn
              class="mint darkgrey--text text-h4 font-weight-bold mr-0"
              to="/travelinfocanada"
            >
              apply now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4-mobile pb-10">
        <v-row class="pt-8">
          <v-col class="s4-title-mobile">
            Your (mis)adventures, covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            Blanket’s travel insurance can cover your unique travel needs with the following products:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in travelNeeds"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            For trips with other unique activities and needs, optional coverages are available as well.
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in optionalCoverages"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
      </div>
      <div class="s5-mobile">
        <v-row class="pt-10">
          <v-col class="s5-title-mobile">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s5-sub-mobile">
            Get Travel coverage in less time than it takes to clean your sunglasses.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center pt-6">
            <v-btn
              x-large
              class="mint mx-auto py-8"
              to="/travelinfocanada"
            >
              <span class="section2-btn-txt-mobile pt-1">get your travel <br> policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          class="phone-mobile"
          src="/img/phone-mobile.png"
        ></v-img>
      </div>
      <div class="faq-sec-desk mobile pt-16 pb-16 px-2">
        <!---------------------------------COVID-19 Update - October 4, 2022------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleCovid19Faq"
          >
            <v-col
              cols="12"
              class="faq-header-mobile"
            >
              COVID-19 Update - October 4, 2022
              <v-icon
                v-if="!covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="covid19Faq">
          <v-col>
            <v-card>
              <v-card-text>
                <p>
                  <br>
                  COVID-19 benefits are applicable if, before your departure date, you’re compliant with the most current Canadian Federal Government COVID-19 vaccine requirements for entry and/or return to Canada.
                  <br>
                  <br>
                  Since there aren’t currently Federal Government COVID-19 vaccine requirements for entry and/or return to Canada, TuGo’s COVID-19 coverage is available regardless of vaccination status, for policies purchased as of October 4, 2022.
                  <br>
                  <br>
                  See details below.
                  <br>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-mobile"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-if="faq.subSection"
                class="mt-5"
              >
                <v-expansion-panels
                  focusable
                  accordion
                >
                  <v-expansion-panel
                    v-for="item in faq.subSection"
                    :key="item.id"
                  >
                    <v-expansion-panel-header class=" text-h4 font-weight-bold">
                      <div>
                        <v-icon
                          color="#00A1B7"
                          class="subSection mr-5"
                        >
                          {{ item.icon }}
                        </v-icon>
                        {{ item.title }}
                      </div>
                      <template v-slot:actions>
                        <v-icon
                          color="#005F6C"
                        >
                          mdi-chevron-down
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <br>
                      </div>
                      <v-expansion-panels
                        focusable
                        accordion
                      >
                        <v-expansion-panel
                          v-for="subItem in item.list"
                          :key="subItem.id"
                        >
                          <v-expansion-panel-header class="subSection-ques text-h4 font-weight-bold">
                            <div>
                              {{ subItem.ques }}
                            </div>
                            <template v-slot:actions>
                              <v-icon
                                color="#005F6C"
                              >
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div>
                              <br>
                            </div>
                            <div v-html="subItem.ans"></div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'

  export default {
    name: 'Dashboard',
    components: { HeroComponent },
    data () {
      return {
        covid19Faq: false,
        covFaq: false,
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
        travelNeeds: [
          'Emergency medical insurance covers hospital and doctors’ fees, prescriptions, emergency transportation and more while you’re away.', 'Trip cancellation and trip interruption reimburses travel costs if your trip is canceled before you leave or disrupted after it has begun.', 'Accidental death and dismemberment covers you in the event of such unfortunate situations.', 'Non-medical package protects you for missed flights, lost baggage and more (if you don’t need medical insurance coverage).', 'All inclusive holiday package is the travel insurance plan that has it all by protecting for medical emergencies while away, missed flights, lost or damaged baggage and more.'
        ],
        optionalCoverages: [
          'Sports and activities coverage protects the most daring of travelers.', 'Cancel for any reason optional coverage is ideal for travelers who need more flexibility.', 'Baggage insurance is ideal for those traveling outside of their home provinces and visitors to Canada who want security in case they get to their destination and their luggage doesn’t.', 'Rental car covers your rental vehicle against damage or loss whether you’re traveling in the next province or abroad.'
        ],
        mainFaqList: [
          {
            status: false,
            title: 'Buying Travel Insurance FAQs',
            list: [
              {
                ques: 'Why should I buy travel insurance?',
                ans: 'Whether you\'re a Canadian adventuring abroad or taking a trip to a neighboring province, or a visitor exploring Canada, travel insurance protects you and your travel investment. Be confident you\'re covered when dealing with a medical emergency, travel delay and more.'
              },
              {
                ques: 'Why should I buy travel insurance if I already have credit card coverage?',
                ans: 'While some credit cards provide basic travel insurance, this coverage is often limited. Compare Blanket Travel Insurance to your credit card coverage.'
              },
              {
                ques: 'Why should I buy travel insurance, when I have provincial government healthcare?',
                ans: 'Provincial healthcare provides limited coverage for medical treatment and hospital costs outside of your home province. Some provincial healthcare plans won\'t even cover expenses like ambulance services, ER fees, emergency dental treatment and prescription drugs. <a href=\'https://blog.tugo.com/en/blog/how-provincial-health-plans-cover-you-abroad\' target=\'_blank\'>  Learn more</a>'
              },
              {
                ques: 'When should I buy travel insurance?',
                ans: 'It’s ideal to buy travel insurance as soon as you’ve booked your reservations and/or paid your deposit. This is particularly important for Trip Cancellation & Trip Interruption Insurance. Your plan should cover your entire trip, including the day you leave and the day you return.'
              },
            ],
          },
          {
            status: false,
            title: 'Insurance Terms FAQs',
            list: [
              {
                ques: 'What’s a ‘benefit’?',
                ans: 'A \'benefit\' is the eligible expense(s) that the insurance company will cover.'
              },
              {
                ques: 'What’s a ‘covered risk’?',
                ans: 'A ‘covered risk’ identifies valid reasons the insurance company will provide coverage for. For example, doctors\' fees are a benefit of the Emergency Medical plan. Under Trip Cancellation & Trip Interruption Insurance, there are many different covered risks that provide access to different benefits, depending on the situation. '
              },
              {
                ques: 'What’s a ‘condition’?',
                ans: 'A ‘condition’ is a requirement in the policy for coverage to apply.'
              },
              {
                ques: 'What’s an ‘exclusion’?',
                ans: 'An ‘exclusion’ specifies what isn’t covered under the policy.'
              },
              {
                ques: 'What’s a ‘Medical Questionnaire’?',
                ans: 'A Medical Questionnaire (MQ) is a series of questions related to the health of the traveler applying for Emergency Medical Insurance coverage. Blanket requires Canadians and visitors to Canada 60 years and older to complete the MQ, which includes eligibility and rate qualification questions to determine the travel insurance premium cost.'
              },
              {
                ques: 'What’s a ‘deductible’?',
                ans: 'While some definitions may differ between plans, and may not apply to all types of plans, we define deductible as “the portion of eligible expenses you must pay from your own pocket when an eligible claim occurs”. For emergency medical insurance plans for Canadians, the deductible applies to the expenses remaining after payment by your provincial or territorial government healthcare plan. The deductible applies per traveler, per incident claimed.'
              },
            ],
          },
          {
            status: false,
            title: 'Coverage and Policy-related FAQs',
            list: [
              {
                ques: 'Does Blanket offer family coverage?',
                ans: 'Yes, our “Family & Friends plan”offers coverage for two adults 59 and younger, traveling with up to 6 dependent children. <a href=\'https://blog.tugo.com/en/blog/group-travel-insurance-for-family-and-friends-infographic/\' target=\'_blank\'>  Learn more</a>'
              },
              {
                ques: 'Are sports-related injuries covered?',
                ans: 'Yes, Blanket automatically covers sports and activities under the Emergency Medical or Visitor to Canada Emergency Medical plan, unless listed as an exclusion. Some sports, which are listed under the optional Sports & Activities Coverage, require an additional premium in order for you to be covered while participating in, training or practicing for them.'
              },
              {
                ques: 'What happens if I need to extend my policy because I’m staying away longer?',
                ans: 'All you have to do is contact your insurance professional or Blanket’s Customer Service team. <a href=\'https://blog.tugo.com/en/blog/how-to-extend-your-travel-insurance-policy/\' target=\'_blank\'>  Learn more</a>'
              },
              {
                ques: 'My contact or trip details have changed, or the information in my policy declaration is wrong. Do I have to notify anyone?',
                ans: 'Yes, please! If any information has changed, it could impact your coverage. Please contact your insurance professional or  <a href=\'https://www.tugo.com/en/company/contact-us/\' target=\'_blank\'> our partner’s customer service</a> to update your details.'
              },
              {
                ques: 'Can I cancel a policy?',
                ans: 'You can cancel most policies before or on the effective date, but may not after. To cancel a policy, please contact your insurance professional or <a href=\'\'> our partner’s customer service</a>.'
              },
              {
                ques: 'Can I purchase Trip Cancellation & Trip Interruption coverage after I\'ve already left on my trip?',
                ans: 'Coverage for Trip Cancellation & Trip Interruption for an existing trip can\'t be purchased after departure. Trip Interruption Only coverage is also not available for purchase after departure.<br><br>However, you can purchase a policy with Trip Cancellation & Trip Interruption Insurance after departure, but only for a new side-trip booked while you’re already away. Keep in mind, that in the event of a trip interruption claim where you need to be returned to your point of departure, it will be to the place you departed from to start the side-trip.'
              },
            ],
          },
          {
            status: false,
            title: 'COVID-related FAQs',
            list: [
              {
                ques: 'Will my Emergency Medical Insurance policy cover me for COVID-19 when I travel outside of Canada?',
                ans: 'Your Emergency Medical Insurance Worldwide or Excluding USA policy will cover you for COVID-19, up to the policy limit or up to each benefit limit as specified in the plan, if you’ve followed all federal travel vaccine requirements issued by the Government of Canada for entry and/or return to Canada, if there are any.<br><br>As of October 4th, since there are no federal Government COVID-19 vaccine requirements for entry and/or return to Canada, COVID-19 coverage is provided regardless of vaccination status.<br><br>If the federal Government of Canada reinstates vaccine requirements for entry/return to Canada while you are away on your trip, coverage will still be provided under the COVID-19 benefit if there was no vaccine requirement prior to departing on your trip. <br><br>If the federal Government of Canada reinstates vaccine requirements for entry/return to Canada in the future, coverage will only be provided under the COVID-19 benefit if you meet these new requirements before your date of departure.  <br><br>Some Emergency Medical Insurance plan benefits have limits that apply. For example, coverage under the Child Care benefit has a limit of $500 per day to a maximum of $5,000 for necessary child care expenses if you’re hospitalized.'
              },
              {
                ques: 'Am I covered for COVID-19 within Canada?',
                ans: 'You’ll be covered by your provincial healthcare plan for COVID-19 and other medical emergencies. That said, you may want to consider purchasing Emergency Medical Insurance for the other costs that can be incurred over and above what your provincial healthcare plan pays for, like an ambulance ride, return of vehicle and more.'
              },
              {
                ques: 'Am I covered for COVID-19 during my upcoming cruise?',
                ans: 'Yes, Blanket’s Emergency Medical Insurance policy will cover travelers regardless of travel advisory levels issued by the Canadian government provided you have met the applicable vaccine requirements outlined by the Government of Canada for entry and/or return to Canada prior to departure, if there are any.'
              },
              {
                ques: 'If I receive a vaccine dose before my trip and have a reaction before my departure, will this be considered a pre-existing medical condition? ',
                ans: 'Yes, it would be a considered a pre-existing medical condition and needs to meet the stability requirements of Emergency Medical Insurance.'
              },
              {
                ques: 'If I receive a vaccine dose before my trip and have a reaction while on the trip, will this be covered? ',
                ans: 'If the onset of the adverse reaction or complication occurs after departure, this would be considered unexpected and coverage would be provided under Emergency Medical Insurance, subject to the policy terms and conditions.'
              },
              {
                ques: 'If I receive a vaccine dose during my trip, will it be covered? ',
                ans: 'No, a vaccine is considered a preventative measure, not an emergency medical treatment. The vaccine would not be covered under our Emergency Medical Insurance, since a vaccine isn’t a medical emergency.'
              },
              {
                ques: 'If I receive a vaccine dose during my trip and have a reaction while on the trip, will this be covered? ',
                ans: 'Yes, you would be covered under Emergency Medical Insurance, subject to the policy terms and conditions. The vaccine itself wouldn’t be covered, since it isn’t an emergency medical treatment.'
              },
              {
                ques: 'If I’ve previously been diagnosed with COVID-19, can I still have coverage for COVID-19? What requirements do I need to meet?',
                ans: 'You’ll be covered for COVID-19 under the Blanket Emergency Medical Insurance plan if you haven’t been diagnosed with COVID-19 on or within 30 days before departure and aren’t showing symptoms on or within 14 days before departure. Keep in mind that just like any other medical condition, coverage for COVID-19 (and any related complications that arose before departure) is subject to the pre-existing medical condition stability period requirements. Refer to your policy wording for details about the stability periods that apply to you.<br><br>If you’re diagnosed with a new case of COVID-19 during your trip that isn’t a continuation of a previous COVID-19 diagnosis, we do consider this to be a new event and coverage would be available, provided you have met all the criteria outlined under the COVID-19 benefit. Please refer to your policy wording for details.'
              },
            ],
            subSection: [
              {
                icon: 'mdi-needle',
                status: false,
                title: 'COVID-19: Partially Vaccinated or Unvaccinated Canadians',
                list: [
                  {
                    ques: 'Am I covered for COVID-19 if I’m not vaccinated?',
                    ans: '<u>Travelling outside of Canada:</u><br><br>TuGo’s COVID-19 benefit is available to you if, before your departure date, you’re compliant with the most current Canadian federal Government COVID-19 vaccine requirements for entry and/or return to Canada.  <br><br>As there isn’t currently no federal Government COVID-19 vaccine requirements for entry and/or return to Canada, TuGo’s COVID-19 coverage is provided regardless of vaccination status. If the federal Government of Canada reinstates vaccine requirements for entry/return to Canada while you are away on your trip, we will still provide coverage under the COVID-19 benefit if there was no vaccine requirement prior to departing on your trip. <br><br>​​​​​​However if the federal Government of Canada reinstates vaccine requirements for entry/return to Canada in the future, we will only provide coverage under the COVID-19 benefit if you meet these new requirements before your date of departure.<br><br><u>Travelling within Canada:</u><br><br>COVID-19 coverage is available regardless of vaccination status.'
                  },
                ],
              },
              {
                icon: 'mdi-test-tube',
                status: false,
                title: 'COVID-19: Tests',
                list: [
                  {
                    ques: 'Would the cost of a COVID-19 test required for travel be covered? ',
                    ans: 'No, since the mandatory COVID-19 test is not a medical emergency, the test (including any additional COVID-19 test taken to meet government requirements) won’t be covered under any of our plans, regardless of the test results.'
                  },
                  {
                    ques: 'If a COVID-19 test is required to return to Canada and comes back positive, will I be covered for emergency medical expenses?',
                    ans: 'Yes, coverage is available under the Emergency Medical Insurance plan.'
                  },
                  {
                    ques: 'If I’m showing symptoms while on my trip and get tested, but my COVID-19 test results come back negative, will the test be covered? ',
                    ans: 'Yes, coverage is available under the Emergency Medical Insurance plan.'
                  },
                  {
                    ques: 'If my policy is expiring and I’m waiting for my mandatory COVID-19 test results, can I extend my policy? ',
                    ans: 'Yes, you can extend your policy, subject to the policy terms and conditions. If the test comes back positive, coverage will be provided for medical expenses, but the cost of the test won’t be covered.'
                  },
                  {
                    ques: 'What happens to my coverage if my policy is expiring and my mandatory COVID-19 test results come back positive, preventing me from returning home as scheduled?',
                    ans: 'An automatic extension to your emergency medical coverage is available, due to a positive COVID-19 test result, when it prevents you from returning home as scheduled and the test results are received within 14 days of your scheduled return date.<br><br>If you\'re eligible for the automatic extension as per the policy wording, you don’t need to contact Blanket; the extension is provided automatically. The number of days available for your automatic extension would vary depending on the reason you need to extend your trip. Please refer to your policy wording for details.<br><br>To support an automatic extension to coverage resulting from a COVID-19 diagnosis made outside the 14-day period, before your scheduled return date, written confirmation from a doctor is needed to confirm that you are medically unfit to travel or still need to quarantine on the scheduled return date.<br><br>Note: When an automatic extension is provided due to a positive COVID-19 test result, the positive test result must include a name and date, as well as the laboratory or medical clinic name. A COVID-19 home test can’t be used to substantiate a claim because it doesn’t specify the date the result was received or who took it. Keep in mind that if you can’t book an official antigen or PCR test to support your claim, you may need to pay for a private test or get written confirmation from a doctor advising that you can\'t travel.'
                  },
                ],
              },
              {
                icon: 'mdi-pill-multiple',
                status: false,
                title: 'COVID-19: Travel Medical for Visitors to Canada & Students',
                list: [
                  {
                    ques: 'Am I covered?',
                    ans: 'If you purchased a new Visitors to Canada Emergency Medical or Student Medical policy on or after July 8, 2020, coverage for COVID-19 may be provided, subject to the terms and conditions of the policy, just like any other medical condition.'
                  },
                ],
              },
              {
                icon: 'mdi-cancel',
                status: false,
                title: 'COVID-19: Trip Cancellation & Trip Interruption',
                list: [
                  {
                    ques: 'Am I covered?',
                    ans: 'If you purchased Trip Cancellation & Trip Interruption on or after November 30, 2021, coverage is provided if you must cancel or interrupt the trip as a result of a medical condition related to COVID-19. If you have an active policy purchased before November 30, 2021, you’ll have coverage for emergencies occurring on November 30, 2021 onwards, even if you’ve already left on your trip.<br><br>Keep in mind that just like any other medical condition, coverage for COVID-19 (and any related complications) is subject to the pre-existing medical condition stability period requirements. Please refer to your policy wording for details about the stability periods that apply.<br><br>If you’re diagnosed with a new case of COVID-19 that isn’t a continuation of a previous COVID-19 diagnosis, we do consider this to be a new event and coverage would be available.<br><br>Note: If you need to cancel because you lost your job or your flight\'s canceled due to COVID-19 (which is considered a known circumstance for travel and therefore no longer considered sudden and unexpected), you won\'t be covered.'
                  },
                  {
                    ques: 'What coverage is available if I have to quarantine due to a positive COVID-19 test result, which prevents me from returning home as scheduled?',
                    ans: 'If you purchased a policy with trip interruption coverage before you left for your trip, you’ll have access to all the applicable trip interruption benefits, which includes coverage for the cost of a one-way economy airfare to your original point of departure (or the cost of a flight change fee in lieu of) as well as out-of-pocket expenses up to $350/day per insured person to a maximum of $1,500 per insured person for commercial accommodation, meals, internet, telephone and taxi expenses.<br><br>Note: You can only claim for the cost of a new flight (or change fees in lieu of), if you incur unexpected expenses due to canceling or rescheduling your flight.<br><br>Keep in mind that trip interruption coverage is provided up to the sum insured amount selected at the time you purchased your policy and as indicated on your policy declaration. This sum insured amount is the total maximum limit for all available interruption benefits, but there are also some interruption benefits that have specific limits. If you’re claiming under a benefit with a specific limit, such as the out-of-pocket expenses outlined above, then we\'ll cover either up to the trip interruption sum insured or up to the limit of the benefit, whichever is less.<br><br>For example: The cost of a new flight was $1,000 and the cost for hotel and meals was $2,000 ($400 per day for 5 days). The total costs incurred was $3,000.<br><br>If you had selected a trip interruption sum insured of $1,500, you can claim up to a maximum of $1,500 for all expenses combined. Total amount payable by our partner: $1,500<br><br>If you had selected a trip interruption sum insured of $10,000, coverage is available for the full cost of the new flight and up to $350 per day to a maximum of $1,500 for your hotel and meals. Total amount payable by our partner: $2,500.'
                  },
                  {
                    ques: 'Can I cancel or interrupt my trip if I or a family member test positive for COVID-19 and need to quarantine? ',
                    ans: 'Yes, Trip Cancellation & Trip Interruption Insurance provides coverage in the event quarantine is required due to COVID-19 sickness for the following: <br><br>  <ul><li>The insured traveler and their family members</li>       <li>The traveling companion and their family members</li>       <li>The host at the travel destination</li> </ul>'
                  },
                  {
                    ques: 'Can I cancel or interrupt my trip if I’ve been exposed to COVID-19 and need to quarantine? ',
                    ans: 'No, coverage isn’t provided if you’ve been contact traced for COVID-19 in your community or at your travel destination.'
                  },
                  {
                    ques: 'Can I cancel due to a travel advisory, border closure or because I’m no longer wanting to travel due to COVID-19 case levels? ',
                    ans: 'Trip Cancellation & Trip Interruption Insurance doesn’t provide coverage if you have to cancel due to COVID-19 travel advisories, border closures, or because you don’t want to travel due to high case levels. However, Trip Cancellation & Trip Interruption Insurance will provide coverage if you must cancel or interrupt your trip due to your medical condition related to COVID-19 (or the medical condition of those listed in the applicable covered risks).'
                  },
                  {
                    ques: 'Does a doctor need to be consulted before I cancel or interrupt my trip because of a COVID-19 diagnosis?',
                    ans: 'While we normally require a doctor to confirm the medical necessity for canceling or interrupting a trip, in the case of a COVID-19 diagnosis, we will accept proof of a positive COVID-19 test in lieu of a medical certificate or letter from a doctor as follows:<br><br>For trip cancellation - written confirmation from a doctor is not required when someone tests positive within 14 days before the scheduled departure date.<br><br>For trip interruption - written confirmation from a doctor is not required for expenses incurred within 14 days from the date a positive COVID-19 test result is received.<br><br>The positive COVID-19 test result must include a name and date, as well as the laboratory or medical clinic name. A COVID-19 rapid test can’t be used to substantiate a claim because it doesn’t specify the date the result was received or who took it. Keep in mind that if you can’t book an official antigen or PCR test to support your claim, you may need to pay for a private test or get written confirmation from a doctor advising that you can\'t travel.<br><br>To support a trip cancellation or interruption claim for COVID-19 that was diagnosed outside these 14-day periods, written confirmation from a doctor is needed to confirm the patient is still medically unfit to travel on the scheduled travel date.'
                  },
                  {
                    ques: 'I have Cancel for Any Reason coverage. Can I cancel because of reasons related to COVID-19?',
                    ans: 'Yes, if you added on this option within 5 days of booking the trip and making an initial payment (or before any cancellation penalties from the travel supplier apply) to your Trip Cancellation & Trip Interruption Insurance, you can enjoy flexibility whenever your trip is canceled for any reason that’s not already listed as a covered risk in the policy. This coverage gives you the flexibility to cancel for any reason more than 5 days before the departure date, for a 50% reimbursement of your prepaid, unused non-refundable travel costs. The terms and conditions of the policy would still apply.'
                  },
                  {
                    ques: 'If I received a vaccine before buying Trip Cancellation & Trip Interruption Insurance, but have a reaction after buying the policy, can I cancel my trip? ',
                    ans: 'Yes, coverage would be provided subject to the policy terms and conditions, if you didn’t have symptoms at the time the insurance was purchased. <br><br>Notes: If you had symptoms/complications before you purchased the insurance, this wouldn’t be covered, since the symptoms were a circumstance known to you–which might reasonably be expected to prevent travel as booked. <br><br>If you purchased the insurance more than 72 hours after booking the trip, any illness, disease, or death occurring within 72 hours of purchasing the insurance wouldn’t be covered.'
                  },
                  {
                    ques: 'If I received a vaccine after buying Trip Cancellation & Trip Interruption Insurance and have a reaction, can I cancel my trip? ',
                    ans: 'Before leaving on your trip: Yes, coverage would be provided subject to the policy terms and conditions.<br><br>While on your trip: Yes, if you had a reaction to a vaccine, coverage would be provided subject to the policy terms and conditions.'
                  },
                ],
              },
              {
                icon: 'mdi-cash-refund',
                status: false,
                title: 'COVID-19: Refunds',
                list: [
                  {
                    ques: 'What about travel medical refunds?',
                    ans: 'If you decided not to go on your trip and your policy has not gone into effect, a full refund may be available to you.<br><br><u>Single Trip Emergency Medical </u><br><br>If you returned early from your trip, a refund is available for your unused number of days on your emergency medical policy, as long as you don’t claim or have any intention of claiming on your policy.<br><br><u>Annual Emergency Medical Plan</u><br><br>If you have traveled on your annual policy, refunds aren’t available. However, if you bought an extension to your Multi Trip Annual policy (for example, you added an extra week to your 10-day annual policy trip length), you may be able to get a refund.<br><br>To proceed with a refund, please contact a Blanket representative. '
                  },
                  {
                    ques: 'If the travel advisory changes to a Level 4 (avoid all travel) and I decide to return home early, am I eligible for a partial refund? ',
                    ans: 'A refund request can be submitted under the Emergency Medical Insurance plan for the unused portion of the trip, if there are no claims submitted under the medical plan.'
                  },
                ],
              },
            ]
          },

        ],
      }
    },
    computed: {
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      largeDevice () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
    },
    created () {
      // this.$gtag.pageview('Travel USA')
      this.currentuser = this.$store.getters.getuser
    },
    methods: {
      toggleFaq (index) {
        this.mainFaqList[index].status = !this.mainFaqList[index].status
      },
      toggleCovid19Faq () {
        this.covid19Faq = !this.covid19Faq
      },
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.s2-icon {
  font-size: 50px !important;
}
.apply-btn {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 22.6667px;
line-height: 125%;
/* or 28px */
text-align: center;
letter-spacing: 0.944445px;
text-transform: uppercase;
/* Color Palette/Dark Grey */
color: #292F36;
}
.s2-desc {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}
  .s2-subhead {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
  }
  .s2-sub {
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
  }
  .cta-btn {
    position: absolute;
    top: 78%;
    left: 9%;
  }
  .cta-txt {
    font-weight: 700;
    font-size: 17.9859px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.749412px;
    text-transform: uppercase;
    color: #292F36;
    padding: 0 !important;
  }
  .section2-header {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
  }
  .s2-title {
    padding-top: 79px;
  }
.title-text-mobile {
    font-weight: 500;
    color: #616161;
  }
.cover{
  font-size: 55px;
  color: #757575;
}
.read-more{
  font-size: 18px;
  color: #00A1B7;
}
.quote-btn{
  font-weight: bold
}
.section2 {
position: relative;
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}
.spacer {
  height: 6rem;
  z-index: 2;
}
.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
}
.pet-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
z-index: 1;
background-color: white;
}
.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}
.btn-mrg1 {
  margin-left: 0;
}
.btn-mrg2 {
  margin-left: 200px;
}
.cov-grp {
  background-color: white;
}
.small {
    height: 700px;
}
.large {
    height: 900px;
}
.zoom {
    transition: transform .2s;
}
.zoom:hover {
    transform: scale(1.2);
}
.section3 {
  position: relative;
  height: 700px;
  background-color: #005F6C;
}
.review-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #FFFFFF;
}
.review-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
/* or 38px */
letter-spacing: -0.424019px;
/* white */
color: #FFFFFF;
}
.review-details {
  font-family: 'Poppins';
font-style: italic;
font-weight: 300;
font-size: 21px;
line-height: 140%;
color: #FFFFFF;
}
.review-sig {
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 140%;
/* or 29px */
/* white */
color: #FFFFFF;
}
.review-ins {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 140%;
/* or 29px */
/* white */
color: #FFFFFF;
}
.portrait-card {
position: absolute;
width: 615px;
height: 700px;
left: 880px;
top: -30px;
background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(1, 0, 0, 0) ), url('/img/pet-review-portrait.png');
border-radius: 10px;
}
.portrait-quote {
  width: 300px;
height: 364px;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 72px;
line-height: 100%;
/* or 72px */
letter-spacing: -0.636172px;
/* Color Palette/White (100%) */
color: #FFFFFF;
}
.portrait-text-container {
  margin-top:100px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px 90px 20px 30px;
width: 100%;
height: 251px;
background: #00A1B7;
flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
z-index: 3;
border-radius: 0 0 0 10px;
}
.portrait-text {
  font-family: 'Poppins';
font-style: italic;
font-weight: 500;
font-size: 17.9695px;
line-height: 125%;
letter-spacing: -0.202611px;
color: #FFFFFF;
}
.signature-container{
  position: absolute;
  right: 10rem;
  top: 42rem;
}
.section4 {
background: #FFFFFF;
padding-left: 10%;
padding-right: 10%;
padding-bottom: 163px;
}
.sec4-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */
letter-spacing: -0.424019px;
/* Color Palette/Dark Grey */
color: #292F36;
}
.sec4-sub {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}
.covg-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
}
.covg-cta-txt {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */
letter-spacing: -0.424019px;
/* midnight eagle green */
color: #005F6C;
}
.learn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 125%;
/* identical to box height, or 25px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
/* gunmetal */
color: #292F36;
}
.sec5-dsk {
position: relative;
height: 438px;
background-image: url('/img/partner-background.jpg');
background-position: center;
background-size: cover;
padding-left: 10%;
padding-right: 10%;
}
.sec5-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}
.sec5-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}
.sec5-title {
  position: absolute;
  left: 130px;
  top: 76px;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */
letter-spacing: -0.424019px;
/* Color Palette/White (100%) */
color: #FFFFFF;
}
.sec5-sub {
  position: absolute;
  left: 130px;
  top: 131px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 125%;
width: 598px;
height: 120px;
/* or 40px */
letter-spacing: -0.424019px;
/* white */
color: #FFFFFF;
}
.apply-btn {
  position: absolute;
  left: 130px;
  top: 289px;
}
.faq-sec-desk {
  background: #FFFFFF;
  padding-left: 10%;
  padding-right: 10%;
}
.faq-header-desk {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}
.faq-panel-icon {
  color: #005F6C;
}
.cov-faq-title:hover {
  cursor: pointer;
}
.poppins-300 {
  font-family: 'Poppins';
  font-weight: 300;
}
/*-----------------------------MOBILE STYLES-----------------------------*/
.section1-mobile {
  background-image: url('/img/Mobile-Travel-Hero-Section.jpg');
  background-position: center;
  background-size: cover;
  height: 375px;
}
.section2-mobile {
width: 100vw;
height: 260px;
background: #00A1B7;
}
.section2-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
}
.section2-btn-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 125%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: #292F36;
}
.section3-mobile {
  padding-bottom: 54px;
  background-image: url('/img/knit-mobile.png');
  background-position: center;
  background-size: cover;
  padding-left: 10px;
  padding-right: 10px;
}
.section3-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #292F36;
}
.section3-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}
.covg-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
}
.covg-txt-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}
.section4-mobile {
  padding-bottom: 54px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFFFFF;
}
.s4-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}
.s4-sub-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}
.covg-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
text-align: left;
}
.panel-title-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 150%;
display: flex;
align-items: center;
color: #005F6C;
}
.panel-icon {
  color: #292F36;
  font-size: 20px !important;
}
::v-deep .v-expansion-panel-header--active .v-icon {
  color: #FFFFFF !important;
}
.s5-mobile {
  position: relative;
  min-height: 725px;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url('/img/partner-background-mobile.jpg');
  background-position: center;
  background-size: cover;
}
.s5-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
}
.s5-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #FFFFFF;
}
.phone-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: auto;
}
.faq-header-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.faq-header-mobile {
  padding-right:20%;
}

/*-----------------------------TABLET STYLES-----------------------------*/
.section1-tablet {
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/Travel-US-Background-Desktop.jpg");
  background-position: center;
  background-size: cover;
}
.section2-tablet {
  position: relative;
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}
.faq-header-tablet {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}
.sora-tablet {
  position: absolute;
top: 45%;
left: 5%;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
}
.cta-btn-tablet {
    position: absolute;
    top: 78%;
    left: 5%;
  }
  ::v-deep .v-expansion-panel-header {
    color:#00A1B7;
  }
  ::v-deep .v-expansion-panel--active .v-expansion-panel-header {
    background:#00A1B7;
    color:#FFFFFF;
  }

  ::v-deep .v-expansion-panel-header.subSection-ques {
     background: #ffffff;;
    color: #000000de;
  }
  ::v-deep .v-expansion-panel--active.subSection-ques .v-expansion-panel-header {
    background:#00A1B7;
    color:#FFFFFF;
  }
</style>
